<template>
  <v-dialog
    v-model="showDialog"
    class="resend-document-dialog custom-scrollbar"
    max-width="950px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        class="mr-2"
        :title="title"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      >
        mdi-{{ icon }}
      </v-icon>
    </template>
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ dialogTitle }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <span v-if="loading">Daten werden geladen...</span>
        <template v-else>
          <v-container>
            <display-container
              label="Dokument"
              isSubcomponent
            >
              <data-display
                class="mt-2"
                label="Typ"
              >
                {{ isInvoice ? 'Invoice' : document.documentType }}
              </data-display>
              <data-display
                class="mt-2"
                label="Name"
              >
                {{ isInvoice ? document.invoiceNo : document.documentName }}
              </data-display>
              <data-display
                class="mt-2"
                :label="isInvoice ? 'Rechnungsdatum' : 'Versanddatum'"
              >
                {{ formatDate(isInvoice ? document.bookingDate : document.uploadDate) }}
              </data-display>
            </display-container>
            <display-container
              class="mt-4"
              label="Empfänger"
              isSubcomponent
            >
              <v-select
                v-model="selectedAddress"
                :items="addresses"
                :disabled="sendDocument"
                placeholder="Bitte Empfänger auswählen"
                item-text="addressId"
                item-value="addressId"
                class="mt-2"
              >
                <template v-slot:selection="data">
                  <template>
                    <span v-if="data.item.company">{{ data.item.company }}</span>
                    <template v-else>
                      <span v-if="data.item.firstName">{{ data.item.firstName }}</span>
                      <span v-if="data.item.lastName">&nbsp;{{ data.item.lastName }}</span>
                    </template>
                    &nbsp;|<span v-if="data.item.street">&nbsp;{{ data.item.street }}</span>
                    <span v-if="data.item.zipCode">&nbsp;{{ data.item.houseNumber }}</span>
                    <span v-if="data.item.houseNumberSupplement">&nbsp;{{ data.item.houseNumberSupplement }}</span>
                    ,&nbsp;<span v-if="data.item.zipCode">{{ data.item.zipCode }}</span>
                    <span v-if="data.item.city">&nbsp;{{ data.item.city }}</span>
                    <span v-if="data.item.district && data.item.city !== data.item.district">&nbsp;-&nbsp;{{ data.item.district }}</span>
                  </template>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="data.item.company">{{ data.item.company }}</span>
                        <template v-else>
                          <span v-if="data.item.firstName">{{ data.item.firstName }}</span>
                          <span v-if="data.item.lastName">&nbsp;{{ data.item.lastName }}</span>
                        </template>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="data.item.street">{{ data.item.street }}</span>
                        <span v-if="data.item.zipCode">&nbsp;{{ data.item.houseNumber }}</span>
                        <span v-if="data.item.houseNumberSupplement">{{ data.item.houseNumberSupplement }}</span>
                        ,&nbsp;<span v-if="data.item.zipCode">{{ data.item.zipCode }}</span>
                        <span v-if="data.item.city">&nbsp;{{ data.item.city }}</span>
                        <span v-if="data.item.district && data.item.city !== data.item.district">&nbsp;-&nbsp;{{ data.item.district }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </display-container>
          </v-container>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          :disabled="loading || sendDocument || !selectedAddress"
          :loading="loading || sendDocument"
          color="warning"
          text
          @click="requestResendDocument"
        >
          {{ isInvoice ? 'Rechnung' : 'Dokument' }} erneut versenden
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ArticleSelectionTable from '@/components/seloca/ArticleSelectionTable.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import NotificationObject from '@/main/NotificationObject.js';
import TimeUtility from '@/util/TimeUtility.js';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'ResendDocumentDialog',
  components: {
    DisplayContainer,
    DataDisplay,
    ArticleSelectionTable,
    AddressDisplay
  },
  props: {
    customerId: { type: Number, required: true },
    document: { type: Object, required: true },
    icon: { type: String, required: false, default: 'refresh' },
    title: { type: String, required: false, default: null }
  },
  data: () => ({
    addresses: [],
    showDialog: false,
    loading: true,
    sendDocument: false,
    selectedAddress: null
  }),
  computed: {
    dialogTitle: function () {
      return (
        (this.isInvoice
          ? `Rechnung ${this.document.invoiceNo}`
          : `Dokument ${this.document.documentId}`) + ' erneut versenden'
      );
    },
    isInvoice: function () {
      return (
        this.document.invoiceNo && Number.isInteger(this.document.invoiceNo)
      );
    }
  },
  watch: {
    showDialog: function () {
      if (this.addresses.length >= 1) return;
      HTTP.get(`customer/${this.customerId}/addresses`).then((res) => {
        if (res.status !== 200) return;

        const response = res.data;
        this.addresses = [];

        for (const addressId in res.data) {
          if (!Object.prototype.hasOwnProperty.call(response, addressId)) {
            continue;
          }

          this.addresses.push({
            addressId: parseInt(addressId),
            ...response[addressId]
          });
        }

        this.loading = false;
      });
    },
    getTitle: function (document) {}
  },
  created() {},
  methods: {
    requestResendDocument() {
      this.sendDocument = true;

      const endpoint =
        'documentArchive/' +
        (this.isInvoice ? 'resendInvoice' : 'resendDocument');

      const body = {
        addressId: this.selectedAddress,
        customerId: this.customerId
      };

      if (this.isInvoice) {
        body.invoiceId = this.document.invoiceNo;
      } else {
        body.documentId = this.document.documentId;
      }

      HTTP.post(endpoint, body)
        .then((res) => {
          this.showToast(
            `Dokument '${this.document.documentName}' wurde erfolgreich versendet`,
            'success'
          );
        })
        .catch(({ response }) => {
          this.showToast(
            `Fehler beim versenden des Dokumentes, Status Code ${response.status} - ${response.statusText}`
          );
        })
        .finally(() => {
          this.sendDocument = false;
          this.showDialog = false;
          this.selectedAddress = null;
        });
    },
    formatDate(date) {
      return TimeUtility.formatLocalDate(date);
    },
    showToast(message, type = 'error') {
      console.log(message, type);
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
};
</script>
<style scoped>
.cart-list-container {
  overflow: auto;
  height: auto;
  max-height: 20vh;
}
</style>
<style>
.select-amount-list .v-list--dense .v-list-item {
  min-height: 0px !important;
}
</style>
